import { createSlice } from "@reduxjs/toolkit";

export const InvoiceInitialState = {
  invoices: [],
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: InvoiceInitialState,
  reducers: {
    setInvoices: (state, action) => {
      state.invoices = action.payload.invoices;
    },
  },
});

export const { setInvoices } = invoiceSlice.actions;
