export default {
   //General 
    "Edit":"Modifier",
    "Please fill in all fields.": "Veuillez remplir tous les champs.",
    "Actions": "Actions",
    "Create": "Créer",
    "Change": "Changer",
    "Save": "Enregistrer",
    "Upload": "Importer",
    "Order":"Commander",
    "Add":"Ajouter",
    "Cancel": "Annuler",
    "Delete":"Supprimer",
    "Export File": "Exporter le fichier",
    "Download File": "Télécharger le fichier",
    "Next":"Suivant",     
    "Previous":"Précédent",   
    "is required":"est requis",
    "should be greater than 0":"doit être supérieur à 0",
    "Please select a supplier":"Veuillez sélectionner un fournisseur",
    "Please select a Reglement Mode":"Veuillez sélectionner un mode de réglementation",
    "More Information":"Plus d'information",
    "Close":"Fermé",
    "Selected Articles":"Articles Sélectionnés",
    "Back":"Retour",
    "Select an inventory":"Choisir un inventaire",
    "Search":"Recherche",
    "Print":"Imprimer",
    //Fields
    "Phone Number": "Numéro de téléphone",
    "Business Name": "Raison Sociale",
    "Contact": "Contact",
    "Address": "Adresse",
    "Choose File": "Choisir un fichier",
    "Postcode": "Code postal",
    "City": "Ville",
    "Vat Number": "Numéro de TVA",
    "Country": "Pays",
    "Reference": "Référence",
    "Designation": "Désignation",
    "Size": "Taille",
    "Quantity": "Quantité",
    "Pre-Tax Price": "PrixHT",
    "Price including Tax": "PrixTTC",
    "Stock Alert": "Alerte de stock",
    "Image": "Image",
    "Change Image":"Changer votre Image",  
    "Choose New Image":"Choisir nouvel image",  
    "Change Password":"Modifer mot de passe",  
    "Old Password":"Ancien mot de passe",  
    "New Password":"Nouveau mot de passe",  
    "Bill Number":"Numéro de facture",
    "TotalHT":"TotalHT",
    "Order date":"Date de commande",
    "Select Mode":"Sélectionnez le mode",
    "Delivery Adress":"Adresse de Livraison",
    "Billing date":"Date de Facturation",
    "Payment Deadlines":"Délais de Paiement",
    //Messages
    "created successfully":"crée avec succées",
    "edited successfully":"edité avec succées",
    "deleted successfully":"supprimé avec succées",
    "Role permissions added successfully":"Autorisations de rôle ajoutées avec succès",
    "Role permission deleted successfully":"Autorisation de rôle supprimée avec succès",
    "File uploaded successfully":"Fichier téléchargé avec succès",
    "Image updated successfully":"Image téléchargé avec succès",
    "Error uploading file":"Erreur lors du téléchargement du fichier",

    // Login 
    "Sign In": "Connexion",
    "Name":"Nom",
    "Email": "Adresse e-mail",
    "Password": "Mot de passe",
    "Confirm Password": "Confirmer le mot de passe",
    "Failed to sign in. Email or Password incorrect.": "Échec de la connexion. E-mail ou mot de passe incorrect.",
    "Sign out": "Déconnexion",
    "Passwords do not match":"Les mots de passe ne correspondent pas",
    //Dashboard
    "Hey":"Bonjour",
    "Dashboard": "Tableau de bord",
    "Edit Profile": "Modifier le profil",
    "Comparison of Theoretical/Actual Inventory":"Comparaison Inventaire Theorique/Réel",
    "Comparison of purchases / month":"Comparaison des achats / mois",
    "Theoretical stock":"Stock théorique",
    "Theoretical stock valuation":"Valorisation stock théorique",
    "Actual stock valuation":"Valorisation stock réel",
    "Ecart value":"Ecart valeur",
    "Inventory ecart compared with to another inventory":"Écart d'inventaire par rapport à Inventaire",
    "Comparison of ecarts/categories":"Comparaison des ecart/ familles",
    "Top 5 ecarts by Affectation":"Top 5 des ecarts par Affectation",
    "Choose Customer":"Choisir un client",
    //Clients 
    "Customer":"Client",
    "Customers":"Clients",
    "Customer Group":"Groupe Clients",
    "Customers Table": "Tableau des Clients",
    "Customer Groups Table": "Tableau des Groupes Clients",
    "Create New Customer": "Créer un nouveau Client",
    "Add New Customer": "Ajouter un nouveau Client",
    "Select a Customer":"Choisir un client",
    "Create New Customer Group": "Créer un nouveau Groupe Client",
    "Edit Customer":"Modifier Le Client",
    "Edit Customer Group":"Modifier Le Groupe Client",
    "Delete Customer":"Supprimer le Client",
    "Delete Customer Group":"Supprimer Le Groupe Client",
    "Search Customers":"Rechercher des Clients",
    "Customer Created successfully":"Client créé avec succès",
    "Search Customers":"Rechercher des Clients",
    "Search Customer Groups":"Rechercher des Groupes Clients",
    "Belongs to the group":"Appartient au groupe",
    "Client Category":"Catégorie de clients",
    "Validate the order":"Valider la commande",
    "Parent Company Name":"Nom du groupe",
    "Yes":"Oui",
    "No":"Non",
    //Users
    "User": "Utilisateur",
    "Users": "Utilisateurs",
    "Users Table": "Tableau des utilisateurs",
    "Create New User": "Créer un nouvel utilisateur",
    "Edit User":"Modifier L'utilisateur",
    "Delete User":"Supprimer L'utilisateur",
    "Choose Role":"Choisir un rôle",
    "Search Users":"Rechercher des utilisateurs",
    "User Created successfully":"Utilisateur créé avec succès",
    "Password is too short":"Le mot de passe est trop court",
    "Select Type":"Choisir un type",
    "All":"Tout",
    //Supplier
    "Supplier": "Fournisseur",
    "Suppliers": "Fournisseurs",
    "Suppliers Table": "Tableau des fournisseurs",
    "Create New Supplier": "Créer un nouveau fournisseur",
    "Supplier Id": "Fournisseur Id",
    "Edit Supplier":"Modifier le Fournisseur",
    "Delete Supplier":"Supprimer le Fournisseur",
    "Search Suppliers":"Rechercher des fournisseurs",
    "Link Articles" :"Lier les articles",
    "Link Selected Articles" :"Lier les articles sélectionnés",
    //Category
    "Category": "Catégorie",
    "Categories": "Catégories",
    "Categories Table": "Tableau des Catégories",
    "Create New Category": "Créer une nouvelle catégorie",
    "Search Categories":"Rechercher des catégories",
    "Edit Category":"Modifier La catégorie",
    "Delete Category":"Supprimer La catégorie",
    //Article 
    "Article": "Article",
    "Articles": "Articles",
    "Articles Table": "Tableau des articles",
    "Create New Article": "Créer un nouveau article",
    "Edit Article":"Modifier L'article",
    "Edit Article Image":"Modifier L'image de l'article",
    "Delete Article":"Supprimer L'Article",
    "Select Supplier":"Choisir un fournisseur", 
    "Select Category":"Choisir une catégorie", 
    "Search Articles":"Rechercher des articles",
    "Add New Supplier":"Ajouter nouveau Fournisseur",
    "Stock Tag Not Activated":"Stock Tag Non Activé",
    "Line":"Ligne",
    "Increment Quantity": "Augmenter la quantité",
    "Decrement Quantity": "Diminuer la quantité",
    "incremented successfully":"incrémenté avec succès",
    "decremented successfully":"décrémenté avec succès",
    "deleted successfully":"supprimé avec succès",
    "Delete Ligne": "Supprimer la ligne",
    "Saving...": "Enregistrement...",
    "Deleting...": "Suppression...",
    "Search Article Details":"Rechercher les détails de l'article",
    "No IT details available":"Aucun détail IT disponible",
    "No more details for this article":"Pas plus de détails pour cet article",
      
    //BDC
    "BDC Table":"Tableau des BDC",
    "BDC RB Table":"Tableau des BDC RB",
    "Create New BDC": "Créer un nouveau BDC",
    "Search BDC":"Rechercher des BDC",
    "Search BDC RB":"Rechercher des BDC RB",
    "Edit Status":"Modifier le statut",
    "Status":"Statut",
    "All":"Tout",
    "Done":"Envoyé",
    "In progress":"En cours",
    "Canceled":"Annulée",
    "Order Information":"Informations sur la commande",
    "Order details":"Détails sur la commande",
    "Add New Article":"Ajouter un nouveau Article",
    "Total Amount":"Montant total",
    "BDC In Progress":"BDC En Cours",
    "BDC Details Table":"Tableau des détails du BDC",
    
    //Role
    "Role": "Rôle",
    "Create New Role":"Créer un nouveau rôle",
    "Edit Role":"Modifier le rôle",
    "Add Permission":"Ajouter Permission",
    "Your Permissions":"Votre Permissions",
    "Select All Permissions":"Sélectionner toutes les Permissions",
    "Deselect All Permissions":"Désélectionner toutes les Permissions",
    "No permission to add.":"Aucune permission à ajouter.",

    //Inventory
    "Inventory":"Inventaire",
    "Inventory Date":"Date inventaire",
    "Choose Debut Date":"Choisir un date début",
    "Date Debut":"Date Début",
    "End Date":"Date Fin",
    "Finished":"Fini",
    "Inventory Table":"Tableau Inventaire",
    "Inventory Details Table":"Tableau des lignes inventaire",
    "Lost Article Table":"Tableau des articles perdus",
    "Ecart Inventory Table":"Tableau des écarts d'inventaire",
    "Search Inventory":"Rechercher des inventaires",
    "Search Inventory Details":"Rechercher des lignes d'inventaires",
    "Unit price":"Prix unitaire",
    "Price":"Prix",
    "No missing articles found.":"Aucun article manquant trouvé.",
    //Affectation
    "Affectation Table": "Tableau des Affectations",
    "Create New Affectation":"Créer un nouveau Affectation",
    "Search Affectations":"Rechercher des Affectations",
    "Category Affectation": "Categories Affectations",
    "Category Affectation Table": "Tableau des Categories Affectations",
    "Create New Category Affectation":"Créer un nouveau Categorie Affectation",
    "Search Category Affectation":"Rechercher des Categories Affectations",
    //tag
    "Category Tags Table": "Tableau des familles Tag",
    "Category Tags ": "Familles Tag",
    "Create New Category Tag":"Créer un nouveau famille Tag",
    "Search Tags":"Rechercher des Tags",
    //Terminal
    "Terminals Table": "Tableau des Terminaux",
    "Create New Terminal":"Créer un nouveau Terminal",
    "Search Terminals":"Rechercher des Terminaux",
    "Delete Terminal":"Supprimer le Terminal",
    "Model":"Modèle",
    "IMEI address":"Adresse IMEI",
    "Manufacturer":"Fabriquant",
    //Report Ai 
    "Generate AI Report": "Générer un rapport IA",
    "Select the type of data or provide input to generate a detailed AI report.": "Sélectionnez le type de données ou fournissez des informations pour générer un rapport détaillé d'IA.",
    "Number of Tries left:": "Nombre d'essais restants :",
    "Prompt:": "Invite :",
    "Give more details": "Donnez plus de détails",
    "All": "Tous",
    "Article": "Article",
    "Ecart": "Écart",
    "BDC": "BDC",
    "Generating...": "Génération en cours...",
    "Generate Report": "Générer le rapport",
    "Clear": "Effacer",
    "AI Report": "Rapport IA",
    "Download Report": "Télécharger le rapport",
    "Report History": "Historique des rapports",
    "No reports generated yet.": "Aucun rapport généré pour l'instant.",
    "Download": "Télécharger",
    //Invoice
    "Invoices Table": "Tableau des factures",
    "Quotes Table": "Tableau des devis",
    "Invoice":"Facture",
    "Quote":"Devis",
    "Create New Invoice/Quote":"Créer une nouvelle facture/Devis",
    "Create Quote":"Créer un devis",
    "Search Invoice":"Rechercher des factures",
    "Search Quote":"Rechercher des devis",
    "Create Invoice":"Créer une facture",
    "Change Quote to Invoice":"Changer Devis en Facture ",
    "Edit Invoice":"Modifier facture",
    "Invoice Number":"Numero facture",
    "Invoice Date":"Date facture",
    "AmountHT":"MontantHT",
    "AmountVAT":"MontantTVA",
    "AmountTTC":"MontantTTC",
    "Regulation":"Règlement",
    "Delivred":"Livrée",
    "Overdue by":"En retard de",
    "Day":"Jour",
    "Days left":"Jours restants",
    "Paid": "Payée",
    "Overdue": "En retard",
    "3 Days or Less Left": "3 jours ou moins restants",
    "Converted from Quote":"Converti à partir d'un devis",
    //DLC
    "DLC Table":"Tableau des DLC",
    "Enter number of days":"Saisir le nombre de jours",
}
    
