import { combineReducers, createStore } from "@reduxjs/toolkit";

import { persistStore, persistReducer } from "redux-persist";
import { rootSlice } from "./slice/rootSlice";

import storage from "redux-persist/lib/storage"; // Import storage from redux-persist/lib
import { authentificationSlice } from "./slice/auth/AuthSlice";
import { userSlice } from "./slice/user/UserSlice";
import { fournisseurSlice } from "./slice/fournisseur/FournisseurSlice";
import { articleSlice } from "./slice/article/ArticleSlice";
import { bdcSlice } from "./slice/bdc/BdcSlice";
import { modeSlice } from "./slice/mode/ModeSlice";
import { rolesSlice } from "./slice/roles/RolesSlice";
import { permissionSlice } from "./slice/permission/PermissionSlice";
import { rolePermissionSlice } from "./slice/rolePermission/RolePermissionSlice";
import { inventorySlice } from "./slice/inventory/InventorySlice";
import { modalSlice } from "./slice/modal/ModalSlice";
import { categorySlice } from "./slice/categorie/CategorySlice";
import { clientSlice } from "./slice/client/ClientSlice";
import { familleTagSlice } from "./slice/familleTag/FamilleTagSlice";
import { attributionSlice } from "./slice/attribution/AttributionSlice";
import { dashboardSlice } from "./slice/dashboard/DashboardSlice";
import { terminauxSlice } from "./slice/terminaux/TerminauxSlice";
import { invoiceSlice } from "./slice/invoice/InvoiceSlice";
import { consommateurSlice } from "./slice/consommateur/ConsommateurSlice";
// export const BaseURI = "http://localhost:8443";
// export const BaseURI = "https://realitybird.com:8443";
// export const BaseURI = "http://213.165.86.117:8443";
export const BaseURI = "https://rbscan.realitybird.com:8000";
// export const BaseURI = "http://192.168.1.164:8443";
// export const BaseURI = "http://192.168.1.174:8443";
// export const BaseURI = "http://192.168.1.183:8443";

// export const BaseURI = "http://51.254.103.101:8000";
// export const BaseURI = "https://backend-htk4.onrender.com";
// export const BaseURI = "http://192.168.1.174:8000";

// export const BaseURI = "http://217.160.76.44:8443";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "root",
    "authentification",
    // "bdc",
    // "inventory",
    // "user",
    // "fournisseur",
    // "category",
    // "article",
    // "mode",
    // "roles",
    // "permission",
    // "modal",
    // "client",
  ],
};

const rootReducer = combineReducers({
  root: rootSlice.reducer,
  authentification: authentificationSlice.reducer,
  user: userSlice.reducer,
  fournisseur: fournisseurSlice.reducer,
  category: categorySlice.reducer,
  article: articleSlice.reducer,
  bdc: bdcSlice.reducer,
  mode: modeSlice.reducer,
  roles: rolesSlice.reducer,
  permission: permissionSlice.reducer,
  rolePermission: rolePermissionSlice.reducer,
  inventory: inventorySlice.reducer,
  modal: modalSlice.reducer,
  client: clientSlice.reducer,
  familleTag: familleTagSlice.reducer,
  attribution: attributionSlice.reducer,
  dashboard: dashboardSlice.reducer,
  terminaux: terminauxSlice.reducer,
  invoice: invoiceSlice.reducer,
  consommateur: consommateurSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer);

export const persistor = persistStore(store);
